import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadDefaultMessages() {
  const messages = {
    en: require('../locales/en.sfdc.json'),
  }

  if (Vue.prototype.$msdcrm) {
    const overriddenKeys = require('../locales/en.msdcrm.json')

    messages.en = {
      ...messages.en,
      ...overriddenKeys,
    }
  }

  return messages
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadDefaultMessages(),
})
