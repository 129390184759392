export default [
  {
    path: 'signin',
    name: 'sign-in',
    component: () =>
      import(/* webpackChunkName: "view-signin" */ '@/views/auth/SignIn.vue'),
  },
  {
    path: 'signup',
    name: 'sign-up',
    component: () =>
      import(/* webpackChunkName: "view-signup" */ '@/views/auth/SignUp.vue'),
  },
  {
    path: 'finish-account-signup',
    name: 'finish-account-signup',
    component: () =>
      import(
        /* webpackChunkName: "view-finish-account-signup" */ '@/views/auth/FinishAccountSignup.vue'
      ),
  },
  {
    path: 'finish-adding-user',
    name: 'finish-adding-user',
    component: () =>
      import(
        /* webpackChunkName: "view-finish-adding-user" */ '@/views/auth/FinishAddingUser.vue'
      ),
  },
  {
    path: 'forgot',
    name: 'forgot-password',
    component: () =>
      import(
        /* webpackChunkName: "view-forgot-password" */ '@/views/auth/ForgotPassword.vue'
      ),
  },
]
