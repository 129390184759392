export default [
  {
    path: '',
    name: 'on-entry-find-duplicates',
    component: () =>
      import(
        /* webpackChunkName: "view-on-entry" */ '@/views/on-entry/OnEntryFindDuplicates.vue'
      ),
  },
  {
    path: 'dashboard',
    name: 'on-entry-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "view-on-entry-dashboard" */ '@/views/on-entry/OnEntryAdminDashboard.vue'
      ),
  },
  {
    path: 'review',
    name: 'on-entry-review',
    component: () =>
      import(
        /* webpackChunkName: "view-on-entry-review" */ '@/views/on-entry/OnEntryDuplicatesToReview.vue'
      ),
  },
]
