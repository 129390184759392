export default [
  {
    path: 'dashboard',
    name: 'clean-verify-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "view-clean-verify-dashboard" */ '@/views/clean-verify/CleanVerifyDashboard.vue'
      ),
  },
  {
    path: 'address-verifications',
    name: 'clean-verify-address-verifications-list',
    component: () =>
      import(
        /* webpackChunkName: "view-clean-verify-address-verifications-list" */ '@/views/clean-verify/AddressVerificationsList.vue'
      ),
    meta: {
      breadcrumb: [
        {
          name: 'Address Verification',
          link: '/clean-verify/dashboard',
          disabled: false,
        },
        { name: 'Verified Addresses', disabled: true },
      ],
    },
  },
  {
    path: 'address-verification',
    name: 'clean-verify-address-verification-widget',
    component: () =>
      import(
        /* webpackChunkName: "view-clean-verify-address-verification-widget" */ '@/views/clean-verify/AddressVerificationsWidget.vue'
      ),
  },
]
