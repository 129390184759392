export default {
  primary: '#224979',
  secondary: '#20C0F4',
  accent: '#016DCC',
  info: '#20C0F4',
  success: '#54a15e',
  error: '#B85D0D',
  default: '#fff',
  warning: '#fcbb15',

  'ap-dark-blue': '#224979',
  'ap-dark-blue-20': '#c7d9ef',
  'ap-dark-blue-10': '#E9EDF2',

  'ap-light-blue': '#20C0F4',
  'ap-light-blue-10': '#E9F9FE',

  'ap-blue': '#016DCC',
  'ap-blue-10': '#E6F1FA',

  'ap-black': '#000000',

  'ap-dark-gray': '#706E6B',

  'ap-gray': '#B9B9B9',

  'ap-grey': '#d8d8d8',

  'ap-light-grey': '#F3F2F2',
  'ap-white': '#FFFFFF',

  'ap-red': '#B85D0D',
  'ap-red-10': '#F8EFE7',

  'ap-orange': '#FF7700',
  'ap-orange-10': '#FFF2E6',

  'ap-yellow': '#FCBB15',
  'ap-yellow-10': '#FFF8E8',

  'ap-green': '#54A15E',
  'ap-green-10': '#EEF6EF',
}
