import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import sfdcColors from './vuetify/sfdc.colors'
import msdcrmColors from './vuetify/msdcrm.colors'
import * as Icons from './mdi-icons'
import progressArrow from '@/components/icons/IconProgressArrow'
import chain from '@/components/icons/IconChain'

const icons = {}

for (const key in Icons) {
  // Create values for icons => { 'mdi-cog': mdiCog }
  icons[key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()] = Icons[key]
}

if (process.env.VUE_APP_SPA === 'true') {
  const DevIcons = require('./mdi-dev-icons')

  for (const key in DevIcons) {
    icons[key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()] = DevIcons[key]
  }
}

Vue.use(Vuetify)

export function setSystemTypeColors(systemType) {
  if (systemType === 'msdcrm') {
    vuetify.framework.theme.themes.light = {
      ...vuetify.framework.theme.themes.light,
      ...msdcrmColors,
    }
  }
}

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      ...icons,
      progressArrow: {
        component: progressArrow,
      },
      chain: {
        component: chain,
      },
    },
  },
  theme: {
    themes: {
      light: sfdcColors,
    },
    options: {
      variations: false,
      customProperties: true,
    },
  },
})

export default vuetify
