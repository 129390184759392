import { SignJWT } from 'jose'

export const generateJwt = async (message) => {
  const payload = {
    org_id: message.orgId,
    session_id: message.sessionId,
    permission_name: message.permissionName,
    user_id: message.userId,
    user_email: message.email,
    user_first_name: message.firstName,
    user_last_name: message.lastName,
    user_name: message.userName,
    totp: message.totp,
    data: {
      entity_key: message.data.entityKey,
      entity_name: message.data.entityName,
    },
  }

  const privateKey = Buffer.from(process.env.VUE_APP_JWS_SECRET)

  return await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .sign(privateKey)
}
